// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import './stylesheets/application.scss';
import "bootstrap";
import "select2";
import "select2/dist/js/i18n/ja";
import "./controllers";
import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";

import "@hotwired/turbo-rails";

require.context("./images", true);

document.addEventListener("turbo:load", function(){
  $('[data-toggle="tooltip"]').tooltip();

  $('.side-toggle').on('click',function(){
    if ($('.sidebar').hasClass('sidebar-drawer')) {
      $(this).removeClass('sidebar-drawer')
    }
    $('.drawer-overlay').css('display','block');
    $('.sidebar').addClass('sidebar-drawer');
    $(this).css('visibility', 'hidden');
  });
  $('.drawer-overlay').on('click',function(){
    if ($('.sidebar').hasClass('sidebar-drawer')) {
      $('.sidebar').removeClass('sidebar-drawer')
    }
    $('.drawer-overlay').css('display','none');
    $('.side-toggle').css('visibility', 'visible');
  });
});

document.addEventListener("turbo:before-cache", function(){
  // ページ遷移直前のキャッシュ前に要素を掃除する
  $('.alert').remove();
  $('.modal').modal('hide');
  $('.tooltip').tooltip("hide");
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
